import React from "react";
import Ecoindex_V2 from './assets/Ecoindex_V2.mp4'
import { Helmet } from "react-helmet-async";

import "./AboutEcoIndex.css";
const AboutEcoIndex = () => {
  return (
    <div className="about-container">
      <Helmet>
        {/* Meta tags for Resources page */}
        <title>How to Calculate Your Travel Footprint? | EcoIndex Resources</title>
        <meta
          name="description"
          content="Want to Calculate Your Travel Footprint? Explore EcoIndex resources to track CO2 emissions, offset carbon, and take action toward a net zero future."
        />
        <meta
          name="keywords"
          content="Calculate Your Travel Footprint, carbon footprint calculator, net zero carbon emissions, CO2 emissions, reduce carbon footprint, climate action, carbon neutral, global warming solutions, sustainable living, carbon offsetting, scope 1 2 and 3 emissions, ways to reduce global warming, climate change solutions, net zero emissions, carbon pricing"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://ecoindex.ai/resources" />
        <meta name="author" content="EcoIndex" />

        {/* Open Graph Meta Tags (for Facebook & LinkedIn) */}
        <meta property="og:title" content="How to Calculate Your Travel Footprint? | EcoIndex" />
        <meta
          property="og:description"
          content="Learn how to Calculate Your Travel Footprint with expert insights, tools, and guides. Reduce CO2 emissions and work towards a carbon-neutral future."
        />
        <meta property="og:image" content="https://ecoindex.ai/walkthrough.png" />
        <meta property="og:image:alt" content="EcoIndex Resources - Learn About Carbon Footprint" />
        <meta property="og:url" content="https://ecoindex.ai/resources" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="EcoIndex" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How to Calculate Your Travel Footprint? | EcoIndex" />
        <meta
          name="twitter:description"
          content="Find out how to measure and reduce your travel carbon footprint. Access expert guides, tools, and solutions for a net zero future with EcoIndex."
        />
        <meta name="twitter:image" content="https://ecoindex.ai/guide_img.png" />
        <meta name="twitter:image:alt" content="EcoIndex Resources - Learn About Carbon Footprint" />
        <meta name="twitter:url" content="https://ecoindex.ai/resources" />
        <meta name="twitter:site" content="@Ecoindex_ai" />

        {/* About Us Meta Tags */}
        <title>EcoIndex - Calculate Your Travel Footprint Sustainably</title>
        <meta
          name="description"
          content="EcoIndex helps you Calculate Your Travel Footprint, track CO2 emissions, offset carbon, and take climate action for a net zero future."
        />
        <meta
          name="keywords"
          content="carbon footprint calculator, net zero carbon emissions, CO2 emissions, reduce carbon footprint, climate action, carbon neutral, global warming solutions, sustainable living, carbon offsetting, scope 1 2 and 3 emissions, ways to reduce global warming, climate change solutions, net zero emissions, carbon pricing"
        />
        <link rel="canonical" href="https://ecoindex.ai/aboutecoindex" />

        {/* Open Graph Meta Tags for About Us */}
        <meta property="og:video" content="https://api.ecoindex.ai/download/assets/Ecoindex_V2.706b0d70f816087ca819.mp4" />
        <meta property="og:video:type" content="video/mp4" />
        <meta property="og:video:width" content="1280" />
        <meta property="og:video:height" content="720" />
        <meta property="og:image" content="https://ecoindex.ai/static/media/about_us_thumbnail.png" />
        <meta property="og:image:alt" content="EcoIndex - About Us" />

        {/* Twitter Meta Tags for About Us */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About EcoIndex - Your Partner in Climate Action" />
        <meta
          name="twitter:description"
          content="EcoIndex helps you track and reduce carbon emissions. Learn more about our vision for a sustainable, carbon-neutral future."
        />
        <meta name="twitter:image" content="https://ecoindex.ai/static/media/about_us_thumbnail.png" />
        <meta name="twitter:image:alt" content="EcoIndex - About Us" />
        <meta name="twitter:url" content="https://ecoindex.ai/aboutecoindex" />
        <meta name="twitter:site" content="@Ecoindex_ai" />

        {/* Geotagging Meta Tags for Local SEO */}
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Ghaziabad, Delhi-NCR" />
        <meta name="geo.position" content="28.641485, 77.371384" />
        <meta name="ICBM" content="28.641485, 77.371384" />
      </Helmet>
      <div className="video-container">
        <h1>About EcoIndex</h1>
        <video className="responsive-video" height={600} controls autoPlay>
          <source
            src="https://api.ecoindex.ai/download/assets/Ecoindex_V2.706b0d70f816087ca819.mp4"
            // src = {Ecoindex_V2}
            // type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default AboutEcoIndex;
