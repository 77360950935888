import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ReactSpeedometer from "react-d3-speedometer";
import { getIndividualConsumptionDetailsV2 } from "./apiService";
import ecologo from "./assets/ecologo.png"; // Ensure correct path
import "./NewAddInfoPopup.css";

function NewAddInfoID() {
  const { id } = useParams();
  const [emissionData, setEmissionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmissionData = async () => {
      try {
        const data = await getIndividualConsumptionDetailsV2(id);
        setEmissionData(data);
      } catch (error) {
        console.error("Error fetching emission data:", error);
        alert("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmissionData();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (!emissionData) return <div>No data available for ID {id}</div>;

  const shareImage = "popup-body-snippet(1).png";
  const { eventLocation, accomodationEmissions, eventName, totalEmissions, travelEmissions, mealEmissions } = emissionData;

  // Percentage calculations
  const travelPercentage = totalEmissions ? ((travelEmissions || 0) / totalEmissions) * 100 : 0;
  const accommodationPercentage = totalEmissions ? ((accomodationEmissions || 0) / totalEmissions) * 100 : 0;
  const foodPercentage = totalEmissions ? ((mealEmissions || 0) / totalEmissions) * 100 : 0;

  // Emission messages
  let emissionMessage = "";
  let additionalMessage = "";
  let messageStyle = { color: "#000" };

  if (totalEmissions <= 80) {
    emissionMessage = "Great job! Your emissions are below the average for conference attendees. Keep up the good work.";
  } else if (totalEmissions <= 160) {
    emissionMessage = "Well done! Your emissions are in line with the average for conference attendees. Every step counts toward sustainability!";
  } else {
    emissionMessage = "Oops! Your emissions are higher than the average for conference attendees.";
    if (travelPercentage > 50) additionalMessage = "Try switching to greener transport options for the next event.";
    else if (accommodationPercentage > 50) additionalMessage = "Try switching to greener stay options for the next event.";
  }

  return (
    <div className="popup-overlay">
      {/* Metadata for social media sharing */}
      <Helmet>
        <title>{eventName} - Emission Report</title>
        <meta name="description" content={`Emission report for ${eventName} at ${eventLocation}`} />
        <meta property="og:title" content={`${eventName} - Emission Report`} />
        <meta property="og:description" content={`Check your emissions for the event at ${eventLocation}.`} />
        <meta property="og:image" content={shareImage} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${eventName} - Emission Report`} />
        <meta name="twitter:description" content={`Your total emissions: ${totalEmissions} Kg CO₂`} />
        <meta name="twitter:image" content={shareImage} />
      </Helmet>

      <div className="popup-container">
        <div className="popup-header">
          <img src={ecologo} alt="Eco Logo" />
          <p>Your Emission</p>
        </div>

        <div className="popup-body">
          <div className="emission-summary">
            <div className="emission-summary-details">
              <p>Total Emission</p>
              <h1 className="total-emission">
                <b>{totalEmissions || 0}</b> Kg CO₂
              </h1>

              <div className="bar-container">
                {/* Accommodation Bar */}
                <div className="skill-box">
                  <span className="bar-title">
                    Accommodation: <b>{accomodationEmissions || 0}</b> KgCO₂
                  </span>
                  <div className="skill-bar">
                    <span
                      className="skill-per"
                      style={{ width: `${accommodationPercentage}%`, background: "#FFD5F7" }}
                    >
                      <span className="bar-text">{accommodationPercentage.toFixed(1)}%</span>
                    </span>
                  </div>
                </div>

                {/* Travel Bar */}
                <div className="skill-box">
                  <span className="bar-title">
                    Travel: <b>{travelEmissions || 0}</b> KgCO₂
                  </span>
                  <div className="skill-bar">
                    <span
                      className="skill-per"
                      style={{ width: `${travelPercentage}%`, background: "#D7EBFB" }}
                    >
                      <span className="bar-text">{travelPercentage.toFixed(1)}%</span>
                    </span>
                  </div>
                </div>

                {/* Food Bar */}
                <div className="skill-box">
                  <span className="bar-title">
                    Food: <b>{mealEmissions || 0}</b> KgCO₂
                  </span>
                  <div className="skill-bar">
                    <span
                      className="skill-per"
                      style={{ width: `${foodPercentage}%`, background: "#F9F5C7" }}
                    >
                      <span className="bar-text">{foodPercentage.toFixed(1)}%</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Emission Speedometer */}
            <div className="emission-summary-meter">
              <ReactSpeedometer
                width={270}
                height={180}
                needleTransitionDuration={1000}
                needleColor="black"
                minValue={0}
                maxValue={240}
                segments={3}
                segmentColors={["#4dbce8", "#ffa342", "#f00018"]}
                customSegmentStops={[0, 80, 160, 240]}
                labelFontSize={"0px"}
                valueTextFontSize={"0px"}
                value={totalEmissions || 0}
              />
              <div className="popup-text">
                <p style={messageStyle}>
                  <strong style={{ fontSize: "14px" }}>{emissionMessage.split(".")[0]}.</strong>
                  <br />
                  {emissionMessage.split(".").slice(1).join(".")}
                </p>
                {additionalMessage && (
                  <p style={{ fontStyle: "italic", marginTop: "10px" }}>{additionalMessage}</p>
                )}
              </div>
            </div>
          </div>

          {/* Event Details */}
          <div className="info-event">
            <div className="info-event-details">
              <h4>Event Details</h4>
              <p>
                <b>Name:</b> {eventName} <br />
                <b>Location:</b> {eventLocation}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAddInfoID;
