import React, { useState, useEffect, useRef } from "react";
import NewAddInfoPopup from "./NewAddInfoPopup";
import Lottie from "lottie-react";
import "./NewAddInfo.css";
import popup1 from "./assets/anim/popup1.json";
import popup2 from "./assets/anim/popup2.json";
import { useParams } from "react-router-dom";
import { addEventConsumptionV3 } from "./apiService";


function Dropdown({ options, value, onChange, disabled = false, style = {} }) {
  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      style={{
        backgroundColor: disabled ? "#f8f9fa" : "",
        color: disabled ? "#f8f9fa" : "",
        borderColor: disabled ? "#f8f9fa" : "",
        ...style,
      }}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function HeaderForm({
  travelMode,
  setTravelMode,
  stay,
  setStay,
  formState,
  handleInputChange,
}) {
  const [mealPreference, setMealPreference] = useState("No Meals");
  const mealPreferenceOptions = {
    "No Meal": 9,
    Veg: 6,
    "Non Veg": 7,
    Vegan: 8,
  };

  const handleStayChange = (value) => {
    setStay(value);
    handleInputChange("StayDays", value === "Yes" ? 1 : 0);
  };

  return (
    <div className="header-form">
  <div className="header-events">
    <label>
      <p>Event Name</p>
      <input
        type="text"
        placeholder="Event Name"
        className="header-input"
        required
        value={formState.EventName}
        onChange={(e) => {
          const value = e.target.value;
         
            handleInputChange("EventName", value);
          
        }}
      />
    </label>

    <div className="user-info">
      <label>
        <p>Name</p>
        <input
          type="text"
          placeholder="Enter Name"
          className="header-input"
          value={formState.UserName}
          required
          onChange={(e) => {
            const value = e.target.value;
            if (/^[a-zA-Z\s]*$/.test(value)) {
              handleInputChange("UserName", value);
            }
          }}
        />
      </label>
      <label>
        <p>Email ID</p>
        <input
          type="email"
          placeholder="Enter Email"
          required
          className="header-input"
          value={formState.UserEmail}
          onChange={(e) => handleInputChange("UserEmail", e.target.value)}
        />
      </label>
    </div>
  </div>

  <div className="header-dropdowns">
    <div className="user-info">
      <label>
        <p> Primary Travel Mode</p>
        <Dropdown
          options={[
            "Bus",
            "Train",
            "Flight",
            "Metro",
            "Car Pool",
            "Car/Cab",
            "2-Wheeler",
            "Bicycle/Walking",
            "3-Wheeler",
          ]}
          value={travelMode}
          onChange={setTravelMode}
          style={{ width: "100%" }}
        />
      </label>
      <label>
        <p>Meal Preference</p>
        <Dropdown
          options={Object.keys(mealPreferenceOptions)}
          value={Object.keys(mealPreferenceOptions).find(
            (key) =>
              mealPreferenceOptions[key] === formState.MealPreferenceId
          )}
          onChange={(value) =>
            handleInputChange(
              "MealPreferenceId",
              mealPreferenceOptions[value]
            )
          }
          style={{ width: "100%" }}
        />
      </label>
    </div>

    <div className="stay-duration-box">
      <div className="user-info">
        <label className="stay-duration-label">
          <p>Stay at Hotel?</p>
          <Dropdown
            options={["Yes", "No"]}
            value={stay}
            style={{ width: "120%" }}
            onChange={handleStayChange}
          />
        </label>
      </div>
    </div>
  </div>
</div>

  );
}

function Stop({ label, location, onLocationChange, removable, onRemove }) {
  const locationInputRef = useRef(null);

  useEffect(() => {
    const initializeAutocomplete = () => {
      const locationInput = locationInputRef.current;

      const autocomplete = new window.google.maps.places.Autocomplete(
        locationInput,
        {
          strictBounds: false,
        }
      );

      autocomplete.setFields([
        "formatted_address",
        "geometry.location",
        "name",
        "place_id",
      ]);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          onLocationChange("");
          return;
        }
        const selectedText = locationInput.value;
        onLocationChange(selectedText);
      });
    };

    if (window.google && window.google.maps) {
      initializeAutocomplete();
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCpdevcXjKt9CVD1n8chB59MGW0d1uT2dg&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeAutocomplete;
      document.head.appendChild(script);
    }
  }, [onLocationChange]);

  return (
    <div
      className={`timeline-stop ${
        label === "Start Location"
          ? "start"
          : label === "Event Venue"
          ? "end"
          : ""
      }`}
    >
      <div
        className={`circle ${
          label === "Start Location"
            ? "start-circle"
            : label === "Event Venue"
            ? "end-circle"
            : "mid-circle"
        }`}
      ></div>

      <span
        className={`stop-label ${
          label === "Start Location"
            ? "start-label"
            : label === "Event Venue"
            ? "end-label"
            : "mid-label"
        }`}
      >
        {label}
      </span>

      <div className="stop-controls">
        <input
          type="text"
          ref={locationInputRef}
          placeholder="Add Google Location"
          required
          value={location}
          onChange={(e) => onLocationChange(e.target.value)}
        />
        {removable && (
          <button className="remove-stop" onClick={onRemove}>
            -
          </button>
        )}
      </div>
    </div>
  );
}

function Timeline({ travelMode, stay, formState, setFormState }) {
  const defaultStops = {
    Bus: [
      { label: "Start Location", location: "" },
      { label: "Bus Stand 01", location: "" },
      { label: "Bus Stand 02", location: "" },
      { label: "Event Venue", location: "" },
    ],
    Train: [
      { label: "Start Location", location: "" },
      { label: "Train Station 01", location: "" },
      { label: "Train Station 02", location: "" },
      { label: "Event Venue", location: "" },
    ],
    Flight: [
      { label: "Start Location", location: "" },
      { label: "Airport 01", location: "" },
      { label: "Airport 02", location: "" },
      { label: "Event Venue", location: "" },
    ],
    "Car Pool": [
      { label: "Start Location", location: "" },
      { label: "Carpool Pickup 01", location: "" },
      { label: "Carpool Pickup 02", location: "" },
      { label: "Event Venue", location: "" },
    ],
    "Car/Cab": [
      { label: "Start Location", location: "" },
      { label: "Event Venue", location: "" },
    ],
    "2-Wheeler": [
      { label: "Start Location", location: "" },
      { label: "Event Venue", location: "" },
    ],
    "Bicycle/Walking": [
      { label: "Start Location", location: "" },
      { label: "Event Venue", location: "" },
    ],

    Metro: [
      { label: "Start Location", location: "" },
      { label: "Metro Station 01", location: "" },
      { label: "Metro Station 02", location: "" },
      { label: "Event Venue", location: "" },
    ],

    "3-Wheeler": [
      { label: "Start Location", location: "" },
      { label: "Stop 01", location: "" },
      { label: "Event Venue", location: "" },
    ],
    default: [
      { label: "Start Location", location: "" },
      { label: "Event Venue", location: "" },
    ],
  };

  const fuelOptions = {
    Bus: ["Fuel-based", "EV"],
    Flight: ["Economy", "Business"],
    "2-Wheeler": ["Petrol", "Diesel", "EV"],
    "3-Wheeler": ["Petrol", "Diesel", "CNG"],
    default: ["Petrol", "Diesel", "CNG", "EV", "Hybrid"],
  };

  const travelModeToId = {
    // Walking/Basic mode
    Walk: 43,
    "Bicycle/Walking": 44,

    // Bus variations
    "Bus-Fuel-based": 5,
    "Bus-EV": 7,

    // Train
    Train: 6,

    // Flight variations
    "Flight-Economy": 8,
    "Flight-Business": 13,

    Metro: 42,

    // Car Pool variations
    "Car-Pool-Petrol": 2,
    "Car-Pool-Diesel": 1,
    "Car-Pool-CNG": 3,
    "Car-Pool-EV": 4,
    "Car-Pool-Hybrid": 45,

    // Cab variations
    "Cab-Petrol": 2,
    "Cab-Diesel": 1,
    "Cab-CNG": 3,
    "Cab-EV": 4,
    "Cab-Hybrid": 46,

    // 2-Wheeler variations
    "2-Wheeler-Petrol": 47,
    "2-Wheeler-EV": 48,
    // "2-Wheeler-CNG": 1,
    // "2-Wheeler-Hybrid": 1,
    "2-Wheeler-Diesel": 49,

    // 3-Wheeler variations
    "3-Wheeler-Petrol": 50,
    "3-Wheeler-CNG": 51,
    // "3-Wheeler-EV": 1,
    // "3-Wheeler-Hybrid": 1,
    "3-Wheeler-Diesel": 52,
  };

  //defaul stop:travelMode is passed as "BUS" in starting
  const [stops, setStops] = useState(defaultStops[travelMode] || []);
  const [travelModes, setTravelModes] = useState(["Bus"]);
  const [fuelTypes, setFuelTypes] = useState(stops.map(() => ""));
  console.log("fueltypes", fuelTypes);
  console.log("stops", stops);
  const [carpoolSeats, setCarpoolSeats] = useState({});

  const travelModeEmojis = {
    Bus: "🚌",
    Cab: "🚖",
    Walk: "🚶",
    "3-Wheeler": "🛺",
    "Car-Pool": "🚐",
    "2-Wheeler": "🏍️",
    Metro: "🚉",
    Train: "🚆",
    Flight: "✈️",
  };

  //this is to handle traveldetails as per API input class defined
  useEffect(() => {
    const travelDetails = [];
    for (let i = 0; i < stops.length - 1; i++) {
      const currentMode = travelModes[i];
      const currentFuel = fuelTypes[i];

      // Create combined mode-fuel string
      let combinedMode = currentMode;
      if (currentFuel && currentFuel !== "Select Transportation Type") {
        combinedMode = `${currentMode}-${currentFuel}`;
      }

      console.log("Combined Mode:", combinedMode);
      const modeId =
        travelModeToId[combinedMode] || travelModeToId[currentMode] || 1;

      let distance = 1;
      if (
        currentMode === "Car-Pool" &&
        carpoolSeats[i] &&
        carpoolSeats[i] > 0
      ) {
        distance = distance / carpoolSeats[i];
      }
      travelDetails.push({
        FromLoc: stops[i].location,
        ToLoc: stops[i + 1].location,
        TravelModeId: modeId,
        TravelSequence: i + 1,
        Distance: distance,
      });
    }

    setFormState((prev) => ({
      ...prev,
      TravelDetails: travelDetails,
    }));
  }, [stops, travelModes, fuelTypes, carpoolSeats]);

  console.log("travelModesok", travelModes);
  const handleLocationChange = (index, value) => {
    setStops((prev) => {
      const updated = [...prev];
      updated[index].location = value;
      return updated;
    });
  };
  //This is to handle Number of seats for Car Pool
  const handleCarpoolSeatsChange = (index, seats) => {
    setCarpoolSeats((prev) => ({
      ...prev,
      [index]: parseInt(seats) || 0,
    }));
  };

  //To add hotel as a stop
  useEffect(() => {
    setStops((prevStops) => {
      if (stay === "Yes") {
        const hasHotelStop = prevStops.some((stop) => stop.label === "Hotel");
        if (!hasHotelStop) {
          const eventVenueIndex = prevStops.findIndex(
            (stop) => stop.label === "Event Venue"
          );
          if (eventVenueIndex > 0) {
            const newStops = [...prevStops];
            newStops.splice(eventVenueIndex, 0, {
              label: "Hotel",
              location: "",
            });
            return newStops;
          }
        }
      } else {
        const filteredStops = prevStops.filter(
          (stop) => stop.label !== "Hotel"
        );
        return filteredStops;
      }
      return prevStops;
    });
  }, [stay]);
  console.log("Updated formState:", JSON.stringify(formState));

  //This useffect is to handle stops or assigning initial stops using primary travelMode
  useEffect(() => {
    const initialStops = defaultStops[travelMode] || [];
    console.log("initialStops", initialStops);
    // Preserve Hotel if `stay` is "Yes" and travelmode got changed
    setStops((prevStops) => {
      if (stay === "Yes") {
        const hasHotelStop = prevStops.some((stop) => stop.label === "Hotel");
        if (hasHotelStop) {
          const eventVenueIndex = initialStops.findIndex(
            (stop) => stop.label === "Event Venue"
          );
          if (eventVenueIndex > 0) {
            const newStops = [...initialStops];
            newStops.splice(eventVenueIndex, 0, {
              label: "Hotel",
              location: "",
            });
            return newStops;
          }
        }
      }
      return initialStops;
    });

    const initialTravelModes = initialStops.map((stop, index) => {
      // Specific logic for 2-Wheeler and 3-Wheeler
      if (travelMode === "2-Wheeler" || travelMode === "3-Wheeler") {
        return travelMode;
      }

      // Specific logic for "Car/Cab"
      if (travelMode === "Car/Cab") {
        if (
          stop.label === "Start Location" &&
          initialStops[index + 1]?.label === "Event Venue"
        ) {
          return "Cab";
        }
        return "Walk"; // Default for other routes
      }

      // General logic for other travel modes
      if (
        (stop.label === "Bus Stand 01" &&
          initialStops[index + 1]?.label === "Bus Stand 02") ||
        (stop.label === "Train Station 01" &&
          initialStops[index + 1]?.label === "Train Station 02") ||
        (stop.label === "Airport 01" &&
          initialStops[index + 1]?.label === "Airport 02") ||
        (stop.label === "Carpool Pickup 01" &&
          initialStops[index + 1]?.label === "Carpool Pickup 02") ||
        (stop.label === "Metro Station 01" &&
          initialStops[index + 1]?.label === "Metro Station 02")
      ) {
        switch (stop.label) {
          case "Bus Stand 01":
            return "Bus";
          case "Train Station 01":
            return "Train";
          case "Airport 01":
            return "Flight";
          case "Carpool Pickup 01":
            return "Car-Pool";
          case "Metro Station 01":
            return "Metro";
          default:
            return "Walk";
        }
      }

      // Fallback travel mode
      return "Walk";
    });
    const initialFuelTypes = initialTravelModes.map((mode) => {
      if (mode === "Bus") return "Fuel-based";
      if (mode === "Flight") return "Economy";
      if (["Car-Pool", "Cab", "2-Wheeler", "3-Wheeler"].includes(mode))
        return "Petrol";
      return "";
    });
    console.log("initialFuelTypes", initialFuelTypes);

    setTravelModes(initialTravelModes);
    setFuelTypes(initialFuelTypes);
  }, [travelMode]);

  const handleAddStop = (index) => {
    const newStop = { label: "New Stop", location: "" };
    const updatedStops = [...stops];
    updatedStops.splice(index + 1, 0, newStop);
    setStops(updatedStops);

    const updatedModes = [...travelModes];
    updatedModes.splice(index + 1, 0, "Walk");
    setTravelModes(updatedModes);

    const updatedFuels = [...fuelTypes];
    updatedFuels.splice(index + 1, 0, "");
    setFuelTypes(updatedFuels);
  };

  const handleRemoveStop = (index) => {
    const updatedStops = stops.filter((_, i) => i !== index);
    setStops(updatedStops);

    const updatedModes = travelModes.filter((_, i) => i !== index);
    setTravelModes(updatedModes);

    const updatedFuels = fuelTypes.filter((_, i) => i !== index);
    setFuelTypes(updatedFuels);
  };

  const handleTravelModeChange = (index, value) => {
    const updatedModes = [...travelModes];
    updatedModes[index] = value;
    setTravelModes(updatedModes);

    const updatedFuels = [...fuelTypes];
    if (value === "Bus") {
      updatedFuels[index] = "Fuel-based";
    } else if (value === "Flight") {
      updatedFuels[index] = "Economy";
    } else if (["Car-Pool", "Cab", "2-Wheeler", "3-Wheeler"].includes(value)) {
      updatedFuels[index] = "Petrol";
    } else {
      updatedFuels[index] = "";
    }
    setFuelTypes(updatedFuels);
  };

  const handleFuelChange = (index, value) => {
    const updatedFuels = [...fuelTypes];
    updatedFuels[index] = value;
    setFuelTypes(updatedFuels);
  };
  console.log("carpoolSeats", carpoolSeats);
  return (
    <div className="timeline">
      {stops.map((stop, index) => (
        <React.Fragment key={index}>
          <Stop
            label={stop.label}
            location={stop.location}
            onLocationChange={(value) => handleLocationChange(index, value)}
            removable={stop.label === "New Stop"}
            onRemove={() => handleRemoveStop(index)}
          />
          {index < stops.length - 1 && (
            <div
              className="dropdown-container"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ marginRight: "10px", fontSize: "1.5em" }}>
                {travelModeEmojis[travelModes[index]] || ""}
              </span>
              <Dropdown
                options={[
                  "Bus",
                  "Cab",
                  "Walk",
                  "3-Wheeler",
                  "Car-Pool",
                  "2-Wheeler",
                  "Metro",
                  "Train",
                  "Flight",
                ]}
                value={travelModes[index]}
                onChange={(value) => handleTravelModeChange(index, value)}
              />
              {
                //To put into seat input field
              }{" "}
              {travelModes[index] === "Car-Pool" && (
                <input
                  type="number"
                  placeholder="Seats"
                  className="carpool-input"
                  required
                  onChange={(e) =>
                    handleCarpoolSeatsChange(index, e.target.value)
                  }
                  style={{ marginLeft: "10px", width: "60px" }}
                  min="1"
                />
              )}
              {[
                "Bus",
                "Cab",
                "Walk",
                "3-Wheeler",
                "Car-Pool",
                "2-Wheeler",
                "Metro",
                "Train",
                "Flight",
              ].includes(travelModes[index]) && (
                <Dropdown
                  options={
                    fuelOptions[travelModes[index]] || fuelOptions.default
                  }
                  value={fuelTypes[index]}
                  onChange={(value) => handleFuelChange(index, value)}
                  disabled={["Train", "Walk", "Metro"].includes(
                    travelModes[index]
                  )}
                  style={{ marginLeft: "-24px", width: "120px" }}
                />
              )}
            </div>
          )}
          {index < stops.length - 1 && (
            <div className="add-stop-container">
              <button className="add-stop" onClick={() => handleAddStop(index)}>
                +
              </button>
              <div className="connector-line"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

function NewAddInfo() {
  const [travelMode, setTravelMode] = useState("Bus");
  const [stay, setStay] = useState("No");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSecondText, setShowSecondText] = useState(false);


  
  const [formState, setFormState] = useState({
    EventName: "",
    UserName: "",
    UserEmail: "",
    StayDays: 0,
    MealPreferenceId: 9,
    TravelDetails: [
      {
        FromLoc: "",
        ToLoc: "",
        TravelModeId: "",
        TravelSequence: 1,
        Distance: 1,
      },
    ],
  });
  console.log("newformstate", formState);
  const handleInputChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const [emissionData, setEmissionData] = useState(null);

  const handleSubmit = async () => {
    // Validation check
    if (!formState.EventName.trim()) {
      alert("Please fill out the Event Name.");
      return;
    }
    if (!formState.UserName.trim()) {
      alert("Please fill out your Name.");
      return;
    }
    if (!formState.UserEmail.trim()) {
      alert("Please fill out your Email.");
      return;
    }
   
  
    try {
      const submissionData = {
        EventName: formState.EventName,
        UserName: formState.UserName,
        UserEmail: formState.UserEmail,
        StayDays: formState.StayDays,
        MealPreferenceId: formState.MealPreferenceId,
        TravelDetails: formState.TravelDetails.map((detail, index) => ({
          ...detail,
          TravelSequence: index + 1,
        })),
      };
  
      console.log("submissiondata", submissionData);
      const response = await addEventConsumptionV3(submissionData);
      console.log("Submission successful:", response);
  
      setEmissionData(response);
      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  
  useEffect(() => {
    // Update text and image after 10 seconds
    const timer1 = setTimeout(() => {
      setShowSecondText(true);
    }, 4000);

    // Hide popup after 10 seconds
    const timer2 = setTimeout(() => {
      setLoading(false);
    }, 6500);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);
 

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-text">
            <div>
              <Lottie
                animationData={showSecondText ? popup2 : popup1}
                className={`loading-car ${
                  showSecondText ? "popup2-style" : "popup1-style"
                }`}
                style={
                  showSecondText
                    ? { width: "270x", height: "270px" } // Dimensions for popup2
                    : { width: "220px", height: "220px" } // Dimensions for popup1
                }
              />
            </div>
            <div className="loading-text1">
              {showSecondText ? (
                <>
                  <span>Fill the details, it’s quick!</span>
                  <br />
                  to move towards
                  <br />
                  a greener future!
                  <br />
                </>
              ) : (
                <>
                  <span>Ready to discover</span>
                  <br />
                  Your travel's
                  <br />
                  carbon footprint?
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={`new-add-info ${loading ? "blurred" : ""}`}>
        <HeaderForm
          travelMode={travelMode}
          setTravelMode={setTravelMode}
          stay={stay}
          setStay={setStay}
          formState={formState}
          handleInputChange={handleInputChange}
        />
        <h2>
          Please fill out the information below to view your{" "}
          <b>emission summary</b>
        </h2>
        <Timeline
          travelMode={travelMode}
          stay={stay}
          formState={formState}
          setFormState={setFormState}
        />
        <h3>
          <button className="info-save-button" onClick={handleSubmit}>
            Save
          </button>
          {isPopupOpen && (
            <NewAddInfoPopup
              emissionData={emissionData}
              formState={formState}
              onClose={handleClosePopup}
              id={emissionData?.id} // Pass the ID to the popup
              
            />
            
          )}

          
          <i>
            Drive the Change: Choose Sustainable Transportation for a Greener
            tomorrow!
          </i>
          

   
          
        </h3>
      </div>
    </>
  );
}

export default NewAddInfo;
