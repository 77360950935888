import React, { useRef } from "react";
import "./Resources.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet-async";

import {
  Autoplay,
  Navigation,
  EffectCoverflow,
  Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function Resources() {
  const featuresSwiperRef = useRef();
  return (

    
    <div className="resources-container">
      
      <Helmet>
        <title>How to Calculate Your Travel Footprint? | EcoIndex Resources</title>
        <meta
          name="description"
          content="Want to Calculate Your Travel Footprint? Explore EcoIndex resources to track CO2 emissions, offset carbon, and take action toward a net zero future."
        />
        <meta
          name="keywords"
          content="Calculate Your Travel Footprint, carbon footprint calculator, net zero carbon emissions, CO2 emissions, reduce carbon footprint, climate action, carbon neutral, global warming solutions, sustainable living, carbon offsetting, scope 1 2 and 3 emissions, ways to reduce global warming, climate change solutions, net zero emissions, carbon pricing"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://ecoindex.ai/resources" />
        <meta name="author" content="EcoIndex" />

        {/* Open Graph Meta Tags (for Facebook & LinkedIn) */}
        <meta property="og:title" content="How to Calculate Your Travel Footprint? | EcoIndex" />
        <meta
          property="og:description"
          content="Learn how to Calculate Your Travel Footprint with expert insights, tools, and guides. Reduce CO2 emissions and work towards a carbon-neutral future."
        />
        <meta property="og:image" content="https://ecoindex.ai/walkthrough.png" />
        <meta property="og:image:alt" content="EcoIndex Resources - Learn About Carbon Footprint" />
        <meta property="og:url" content="https://ecoindex.ai/resources" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="EcoIndex" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How to Calculate Your Travel Footprint? | EcoIndex" />
        <meta
          name="twitter:description"
          content="Find out how to measure and reduce your travel carbon footprint. Access expert guides, tools, and solutions for a net zero future with EcoIndex."
        />
        <meta name="twitter:image" content="https://ecoindex.ai/guide_img.png" />
        <meta name="twitter:image:alt" content="EcoIndex Resources - Learn About Carbon Footprint" />
        <meta name="twitter:url" content="https://ecoindex.ai/resources" />
        <meta name="twitter:site" content="@Ecoindex_ai" />
      </Helmet>

      <h1 className="resources-heading">Resources</h1>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={2.5}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2,
        }}
        pagination={{ el: ".feature-pagination", clickable: true }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        onBeforeInit={(swiper) => {
          featuresSwiperRef.current = swiper;
        }}
        modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
        className="feature_swiper_container1"
      >
        {/* <div className="resources-grid"> */}
        {/* First Resource Card - PDF Download */}
        <SwiperSlide>
          {({ isActive }) => (
            <div
              className={`resource-card ${
                isActive ? "" : "inactive_feature_card"
              }`}
            >
              <img
                src="/guide_img.png"
                alt="Practical Guide for Net Zero"
                className="resource-image"
              />
              <h3 className="resource-title">
                Practical Guide for Net Zero Carbon Events
              </h3>
              <p className="resource-description">
                Transform every gathering into an eco-conscious celebration.
                This guide offers practical suggestions to help you create
                impactful, planet-friendly events.
                <br /> Download to understand more!
              </p>

              <a
                href="/Preparation_Guide_for_Sustainaable_Event.pdf"
                download="Preperation_Guide_for_Sustainaable_Event.pdf"
                className="read-more-button"
              >
                Download
              </a>
            </div>
          )}
        </SwiperSlide>

        {/* Second Resource Card - PPT Download */}
        <SwiperSlide>
          {({ isActive }) => (
            <div
              className={`resource-card ${
                isActive ? "" : "inactive_feature_card"
              }`}
            >
              <img
                src="/eventDataImg.png"
                alt="Checklist Image"
                className="resource-image"
              />
              <h3 className="resource-title">
                Checklist to capture data for EcoIndex: Your Path to Accurate
                Emission Tracking
              </h3>
              <p className="resource-description">
                A step-by-step guide to gathering essential data for accurate
                carbon emission tracking.
              </p>
              <a
                href="/Event_Data_Checklist.pptx"
                download="Event_Data_Checklist.pptx"
                className="read-more-button"
              >
                Download
              </a>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive }) => (
            <div
              className={`resource-card ${
                isActive ? "" : "inactive_feature_card"
              }`}
            >
              <img
                src="/walkthrough.png"
                alt="Checklist Image"
                className="resource-image"
              />
              <h3 className="resource-title">Walkthrough for EcoIndex</h3>
              <p className="resource-description">
                This video will give you a complete walkthrough of ecoindex
                events
                <br />
                Otherwise, the application can be experienced using the link:
                ecoindex.ai
              </p>
              <a
                href="/walkthrough"
                target="_blank"
                className="read-more-button"
              >
                Watch
              </a>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive }) => (
            <div
              className={`resource-card ${
                isActive ? "" : "inactive_feature_card"
              }`}
            >
              <img
                src="/ecowed.png"
                alt="Wedding Image"
                className="resource-image"
              />
              <h3 className="resource-title">
                A Green Wedding, A Greener Future
              </h3>
              <p className="resource-description">
                From zero-waste decor to ethical vendors, we help you plan a
                wedding that’s as kind to the Earth as it is to your heart
              </p>
              <a
                href="/Eco_Friendly_Wedding_Net_Zero.pdf"
                download="Eco_Friendly_Wedding_Net_Zero.pdf"
                className="read-more-button"
              >
                Download
              </a>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive }) => (
            <div
              className={`resource-card ${
                isActive ? "" : "inactive_feature_card"
              }`}
            >
              <img
                src="/IndividualApp.jpg"
                alt="Individual's App"
                className="resource-image"
              />
              <h3 className="resource-title">EcoIndex Individual's App</h3>
              <p className="resource-description">
                A video to show how our upcoming Individual's App helps you track your emissions from your day to day activities.
              </p>
              <a
                href="/IndividualsApp"
                target="_blank"
                className="read-more-button"
              >
                Watch
              </a>
            </div>
          )}
        </SwiperSlide>
      </Swiper>
      <div className="feature-controller">
        <div
          className="feature-navigation"
          onClick={() => featuresSwiperRef.current?.slidePrev()}
        >
          <KeyboardArrowLeftIcon fontSize="large" />
        </div>
        <div className="pagination-container">
          <div className="feature-pagination"></div>
        </div>
        <div
          className="feature-navigation"
          onClick={() => featuresSwiperRef.current?.slideNext()}
        >
          <KeyboardArrowRightIcon fontSize="large" />
        </div>
      </div>
    </div>
    // </div>
  );
}

export default Resources;
