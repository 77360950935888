import React from "react";
import "./AboutEcoIndex.css";

const WalkThrough = () => {
  return (
    <div className="about-container">
      <div className="video-container">
        <h1>Ecoindex Individual's App</h1>
          <div className="responsive-video" style={{width: "80vw" , height:"600px", backgroundColor: "black"}}>
        <video height={600} controls autoPlay>
          <source
            src="/EcoindexApp.mp4"
            // src = {Ecoindex_V2}
            // type="video/mp4"
          />
            
        </video>
          </div>
      </div>
    </div>
  );
};

export default WalkThrough;
