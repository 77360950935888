import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  pdf,
  Font,
} from "@react-pdf/renderer";
import emailjs from "@emailjs/browser";
import { getWefixUserList, uploadWeFixInviteeTemplates } from "./apiService";
import { useAuth } from "./AuthProvider";
import certificate from "./assets/Certificate - Manu.png";
import co2 from "./assets/Rj.jpeg";

const ManuReport = () => {
  const [userData, setUserData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  Font.register({
    family: "Ubuntu",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
        fontWeight: "bold",
      },
      {
        src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
        fontWeight: "normal",
        fontStyle: "italic",
      },
    ],
  });

  console.log("Profile", user?.profile?.name);

  useEffect(() => {
    // Simulate API call to get user data
    fetchUserData();
  }, []);

  const authorized = [
    "thats.ayush@gmail.com",
    "anjalibansal242@gmail.com",
    "akshatahuja3344@gmail.com",
  ];

  const fetchUserData = async () => {
    // try {
    //   const response = await getWefixUserList();
    //   console.log(response);
    //   setUserData(response);
    // } catch (error) {
    //   setError(error);
    // } finally {
    //   setLoading(false);
    // }

    const users = [
      {
        "userName": "Ayush",
        "userEmail": "thats.ayush@gmail.com",
        "organizationName": "Ayush Enterprises",
        "totalEmission": 234.1
      },
      {
        "userName": "Akshat",
        "userEmail": "akshatahuja3344@gmail.com",
        "organizationName": "Ayush Enterprises",
        "totalEmission": 234.1
      },
      {
        "userName": "Anjali Bansal",
        "userEmail": "anjalibansal242@gmail.com",
        "organizationName": "Anjali Enterprises",
        "totalEmission": 23.4
      },
      {
        "userName": "Abhay",
        "userEmail": "shuklaenter@gmail.com",
        "organizationName": "Shukla Enterprises",
        "totalEmission": 0
      },
      {
        "userName": "Ajay Kumar Pandey",
        "userEmail": "ajay184@gmail.com",
        "organizationName": "Sames Private Limited",
        "totalEmission": 136.728
      },
      {
        "userName": "Akash Poddar",
        "userEmail": "poddar_akash@yahoo.com",
        "organizationName": "Nirvana marketing",
        "totalEmission": 44.31
      },
      {
        "userName": "Alok Menon",
        "userEmail": "astronos@gmail.com",
        "organizationName": "Alpha One Fitness",
        "totalEmission": 253.2
      },
      {
        "userName": "Amit",
        "userEmail": "amit.sscl.slg@gmail.com",
        "organizationName": "Green Hill Group",
        "totalEmission": 202.56
      },
      {
        "userName": "Amitsarkar",
        "userEmail": "bubunamitsarkar@gmail.com",
        "organizationName": "Sieda",
        "totalEmission": 45.576
      },
      {
        "userName": "Ashish Agarwal",
        "userEmail": "wowhomes.solutions@gmail.com",
        "organizationName": "Wow Homes",
        "totalEmission": 126.6
      },
      {
        "userName": "Avijit bhadury",
        "userEmail": "avijit.bhadury@yahoo.in",
        "organizationName": "Summit hotels &resorts",
        "totalEmission": 243.072
      },
      {
        "userName": "Balram Agarwal",
        "userEmail": "ibalramagarwal@gmail.com",
        "organizationName": "Ticket Bazaar",
        "totalEmission": 265.86
      },
      {
        "userName": "Chinmoy chakraborty",
        "userEmail": "c.chakraborty05@gmail.com",
        "organizationName": "Wefix Service Providers Llp",
        "totalEmission": 10.128
      },
      {
        "userName": "Chirag agarwal",
        "userEmail": "chiragag123@gmail.com",
        "organizationName": "Punjaabi hub",
        "totalEmission": 91.152
      },
      {
        "userName": "Kamalakanta Maity",
        "userEmail": "kamalakanta@wefixteam.com",
        "organizationName": "Wefix Service Providers LLP",
        "totalEmission": 30.384
      },
      {
        "userName": "Kamesh Jain",
        "userEmail": "Jain.kamesh97@gmail.com",
        "organizationName": "Lunawat Realty Private Limited",
        "totalEmission": 227.88
      },
      {
        "userName": "Lalit Garg",
        "userEmail": "lalit.sps87@gmail.com",
        "organizationName": "Tripwizard Travel Solution Pvt Ltd",
        "totalEmission": 101.28
      },
      {
        "userName": "Mohit Shahi",
        "userEmail": "mohit.wave@gmail.com",
        "organizationName": "Agrawal Shahi & Associates",
        "totalEmission": 75.96
      },
      {
        "userName": "Mrinmoy Das",
        "userEmail": "mrindas123@gmail.com",
        "organizationName": "Anandaloke Multispeciality Hospital",
        "totalEmission": 8142.912
      },
      {
        "userName": "Pravin Mykhia",
        "userEmail": "architectpravinmukhia1@gmail.com",
        "organizationName": "PMDG",
        "totalEmission": 0
      },
      {
        "userName": "Prem Rai",
        "userEmail": "purchase.mamring@vguard.in",
        "organizationName": "V-guard industries Ltd Unit -3",
        "totalEmission": 243.072
      },
      {
        "userName": "Rajat Singhania",
        "userEmail": "rajatsinghania2@gmail.com",
        "organizationName": "GOVIND ELECTRICALS",
        "totalEmission": 91.152
      },
      {
        "userName": "RANJAN",
        "userEmail": "ranjanjha@voltas.com",
        "organizationName": "VOLTAS LIMITED",
        "totalEmission": 91.152
      },
      {
        "userName": "Shahid Anowar",
        "userEmail": "shahid@wefixteam.com",
        "organizationName": "GTPE MEP Solution LLP",
        "totalEmission": 83.556
      },
      {
        "userName": "Somnath Srivastava",
        "userEmail": "somnath.7srivastava@gmail.com",
        "organizationName": "Waaree Energies Ltd",
        "totalEmission": 91.152
      },
      {
        "userName": "Sumit Mitruka",
        "userEmail": "ceo@summithotels.in",
        "organizationName": "Summit Hotels",
        "totalEmission": 759.6
      },
      {
        "userName": "Tarang dugar",
        "userEmail": "Tarangdugarj@gmail.com",
        "organizationName": "Om Enterprise",
        "totalEmission": 11.394
      }
    ];
    setUserData(users);
  };

  const handleRowSelect = (user) => {
    setSelectedUsers((prev) =>
      prev.some((u) => u.userEmail === user.userEmail)
        ? prev.filter((u) => u.userEmail !== user.userEmail)
        : [...prev, user]
    );
  };

  const generatePDF = (user) => {
    return (
      <Document style={styles.doc} key={user.userEmail}>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <View style={styles.view}>
            <Image src={certificate} />
            <Text style={styles.text1}>This Certificate Acknowledges</Text>
            <Text style={styles.text2}>Manu Yantralaya</Text>
            <Text style={styles.text3}>has offset the carbon emission</Text>
            <Text style={styles.text4}>associated with travel of</Text>
            <Text style={styles.text5}>{user.userName}</Text>
            <Text style={styles.text6}>
              The data was captured during the visit to
            </Text>
            <Text style={styles.text7}>Bharat Mobility Global Expo 2025</Text>
            <Text style={styles.text8}>New Delhi</Text>
            <Text style={styles.text9}>(18th-19th Jan,2025)</Text>
            <Text style={styles.text10}>
              By retiring 1 ton CO2 equivalents of carbon emission
            </Text>
            <Text style={styles.text11}>
              ..................................
            </Text>
            <Text style={styles.text12}>Date</Text>
            <Image style={styles.imgSig} src={co2} />
            <Text style={styles.text13}>
              ..................................
            </Text>
            <Text style={styles.text14}>Signature, CEO</Text>
          </View>
        </Page>
      </Document>
    );
  };
  const handleSendCertificates = async () => {
    try {
      const pdfPromises = selectedUsers.map(async (user) => {
        const pdfDoc = generatePDF(user);
        const pdfBlob = await pdf(pdfDoc).toBlob();
        return {
          user,
          pdfBlob,
        };
      });

      const pdfsWithUsers = await Promise.all(pdfPromises);

      const formData = new FormData();
      pdfsWithUsers.forEach((item, index) => {
        formData.append(`[${index}].UserName`, item.user.userName);
        formData.append(`[${index}].UserEmail`, item.user.userEmail);
        formData.append(
          `[${index}].FileData`,
          item.pdfBlob,
          `${item.user.userName}-certificate.pdf`
        );
      });

      // console.log("FormData contents:");
      // for (const [key, value] of formData.entries()) {
      //   if (value instanceof Blob) {
      //     console.log(`${key}: Blob with size ${value.size} bytes`);
      //   } else {
      //     console.log(`${key}: ${value}`);
      //   }
      // }
      const response = await uploadWeFixInviteeTemplates(formData);

      if (response.ok) {
        console.log("Certificates uploaded successfully.");
      } else {
        const errorText = await response.text();
        console.error("Failed to upload certificates:", errorText);
      }
    } catch (error) {
      console.error("Error during certificate generation/upload:", error);
    }
  };
  const handleDownloadZip = async () => {
    const zip = new JSZip();
    for (const user of selectedUsers) {
      const pdfDoc = generatePDF(user);
      const pdfBlob = await pdf(pdfDoc).toBlob();
      zip.file(`${user.userName}-certificate.pdf`, pdfBlob);
    }
    const content = await zip.generateAsync({ type: "blob" });
    const url = window.URL.createObjectURL(content);
    const link = document.createElement("a");
    link.href = url;
    link.download = "certificates.zip";
    link.click();
  };

  const sendEmail = (user) => {
    emailjs
      .send(
        "service_tl6q7lg",
        "template_jooepoq",
        {
          to_name: user.userName,
          to_email: user.userEmail,
        },
        {
          publicKey: "Dv-4OIBM4ghm-Opyp",
        }
      )
      .then((response) => console.log("Email sent successfully:", response))
      .catch((error) => console.log("Failed to send email:", error));
  };

  const handleSendEmails = () => {
    selectedUsers.forEach((user) => {
      sendEmail(user);
    });
  };

  return (
    <div className="container-fluid">
      {authorized.includes(user?.profile?.email) ? (
        <>
          <div className="row p-3">
            <div className="col-md-12">
              <div className="table-responsive event-table">
                <table className="table mb-0">
                  <thead className="table-header">
                    <tr>
                      <th align="center">
                        <input
                          type="checkbox"
                          checked={userData.length === selectedUsers.length}
                          onChange={(e) =>
                            userData.length === selectedUsers.length
                              ? setSelectedUsers([])
                              : setSelectedUsers([...userData])
                          }
                        />
                      </th>
                      <th>User Name</th>
                      <th>User Email</th>
                      <th>Organization</th>
                      <th>Total Emission</th>
                    </tr>
                  </thead>
                  {userData.length > 0 ? (
                    userData.map((item, index) => (
                      <tbody className="table-body" key={item.userEmail}>
                        <tr>
                          <td align="center">
                            <input
                              type="checkbox"
                              checked={
                                selectedUsers.filter(
                                  (user) => user.userEmail === item.userEmail
                                ).length > 0
                              }
                              onChange={() => handleRowSelect(item)}
                            />
                          </td>
                          <td>{item.userName}</td>
                          <td>{item.userEmail}</td>
                          <td>{item.organizationName}</td>
                          <td>{item.totalEmission} KgCO2</td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <tbody className="table-body">
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          No user found
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div>
            <button className="GreenBtn" onClick={handleSendCertificates}>
              Send Certificates via Email
            </button>
            <button className="GreenBtn" onClick={handleDownloadZip}>
              Download All Certificates as ZIP
            </button>
          </div>
        </>
      ) : (
        <h1>Unauthorized</h1>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    width: "100%",
    flexDirection: "row",
  },
  view: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  text1: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "120px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 20,
    color: "#07750e",
    fontFamily: "Ubuntu",
  },
  text2: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "160px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 30,
    color: "#07750e",
    fontFamily: "Ubuntu",
    fontWeight: "bold",
  },
  text3: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "200px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
  },
  text4: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "230px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
  },
  text5: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "255px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 28,
    color: "#07750e",
    fontFamily: "Ubuntu",
    fontWeight: "bold",
  },
  text6: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "290px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
  },
  text7: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "320px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 20,
    color: "#07750e",
    fontFamily: "Ubuntu",
  },
  text8: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "345px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
  },
  text9: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "370px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
  },
  text10: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "400px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
  },
  text11: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "450px",
    textAlign: "left",
    justifyContent: "left",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
    paddingLeft: "10px",
  },
  text12: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "465px",
    textAlign: "left",
    justifyContent: "left",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
    paddingLeft: "40px",
  },
  text13: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "510px",
    textAlign: "left",
    justifyContent: "left",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
    paddingLeft: "10px",
  },
  text14: {
    position: "absolute",
    left: "50%",
    width: "50%",
    top: "525px",
    textAlign: "left",
    justifyContent: "left",
    fontSize: 15,
    color: "#07750e",
    fontFamily: "Ubuntu",
    paddingLeft: "10px",
  },
  imgSig: {
    position: "absolute",
    left: "50%",
    top: "480px",
    justifyContent: "left",
    paddingLeft: "10px",
    width: "100px",
    height: "auto",
  }
});

export default ManuReport;
