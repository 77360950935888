import React, { useState, useEffect } from "react";
import "./Wefix.css";
import wefiximg from "./assets/wefiximg.png";
import sprout from "./assets/sprout.png";
import logo10 from "./assets/logo10.png";
import { postWeFixData } from "./apiService";
import ReactSpeedometer from "react-d3-speedometer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import WefixPopup from "./WefixPopup";
import { HelmetProvider, Helmet } from "react-helmet-async";

const Wefix = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    number1: "",
    organization: "",
    acDetails: [],
    hoursUsedPerDay: 0,
    hasSolar: "no",
  });

  const [acFields, setAcFields] = useState([1]);
  const [totalEmission, setTotalEmission] = useState(-1);
  const [showPopup, setShowPopup] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email" || name === "name" || name === "organization") {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      return;
    }

    const sanitizedValue = Math.max(0, parseFloat(value) || 0);

    if (name.startsWith("tonnage") || name.startsWith("capacity")) {
      const acIndex = parseInt(name.replace(/\D/g, "")) - 1;
      setFormData((prevFormData) => {
        const updatedACDetails = [...prevFormData.acDetails];
        const acDetail = updatedACDetails[acIndex] || {};
        if (name.startsWith("tonnage")) {
          acDetail.Tonnage = sanitizedValue;
        } else if (name.startsWith("capacity")) {
          acDetail.Quantity = sanitizedValue;
        }
        updatedACDetails[acIndex] = acDetail;
        return { ...prevFormData, acDetails: updatedACDetails };
      });
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  const handleAddAC = () => {
    if (acFields.length < 5) {
      setAcFields([...acFields, acFields.length + 1]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        acDetails: [...prevFormData.acDetails, { Tonnage: "", Quantity: "" }],
      }));
    }
  };

  const handleRemoveAC = (index) => {
    const updatedAcFields = acFields.filter((_, idx) => idx !== index);
    const updatedACDetails = formData.acDetails.filter(
      (_, idx) => idx !== index
    );
    setAcFields(updatedAcFields);
    setFormData((prevFormData) => ({
      ...prevFormData,
      acDetails: updatedACDetails,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (formData.hoursUsedPerDay <= 0) {
      alert("Please enter positive AC usage hours");
      setSubmitting(false);
    } else {
      const requestData = {
        UserName: formData.name,
        UserEmail: formData.email,
        OrganizationName: formData.organization,
        ACDetails: [...formData.acDetails],
        HoursUsedPerDay: formData.hoursUsedPerDay,
        HasSolar: formData.hasSolar === "yes",
      };

      try {
        const response = await postWeFixData(requestData);
        if (response) {
          const emissionValue = response.totalEmission || 0;
          const roundedEmission = parseFloat(emissionValue).toFixed(2);
          console.log("Rounded Total Emission:", roundedEmission);
          setTotalEmission(response.totalEmission);

          console.log("Form data submitted successfully", response);
          setShowPopup(true);
          setSubmitting(false);
        } else {
          console.error("Failed to submit form data");
          setSubmitting(false);
        }
      } catch (error) {
        console.error("Error submitting form data:", error);
      }
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>Wefix - 123Emission Report</title>
        <meta
          name="description"
          content={`Emission report for Wefix at Siliguri`}
        />
        <meta property="og:title" content={`Wefix - Emission Report`} />
        <meta
          property="og:description"
          content={`Check your emissions for the event at Siliguri.`}
        />
        <meta property="og:image" content={wefiximg} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Wefix - Emission Report`} />
        <meta
          name="twitter:description"
          content={`Your total emissions: Too much Kg CO₂`}
        />
        <meta name="twitter:image" content={wefiximg} />
      </Helmet>
      
      <div className="wefixgrid-container">
        <div className="wefix-container">
          <div className="wefix-logo">
            <img src={sprout} alt="" />
            <img src={logo10} alt="" />
          </div>

          <div className="wefix">
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your full name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />

              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your e-mail"
                value={formData.email}
                onChange={handleChange}
                required
              />

              <label htmlFor="organization">Organization</label>
              <input
                type="text"
                name="organization"
                id="organization"
                placeholder="Enter your organization"
                value={formData.organization}
                onChange={handleChange}
                required
              />

              <div className="ac-details">
                <h2>AC Setup & Usage Details</h2>
              </div>

              <div className="form-groups">
                {acFields.map((ac, index) => (
                  <div key={index} className="form-group1">
                    <div className="input-with-unit">
                      <label htmlFor={`tonnage${ac}`}>
                        What is the capacity (in Tons) of your AC?
                      </label>
                      <input
                        type="number"
                        step="0.5"
                        name={`tonnage${ac}`}
                        id={`tonnage${ac}`}
                        value={formData.acDetails[ac - 1]?.Tonnage || ""}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="input-with-unit">
                      <label htmlFor={`capacity${ac}`}>
                        Number of{" "}
                        {formData.acDetails[ac - 1]?.Tonnage
                          ? formData.acDetails[ac - 1]?.Tonnage + " Ton"
                          : ""}{" "}
                        AC
                      </label>
                      <input
                        type="number"
                        name={`capacity${ac}`}
                        id={`capacity${ac}`}
                        value={formData.acDetails[ac - 1]?.Quantity || ""}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="btn-group">
                      <button
                        type="button"
                        onClick={handleAddAC}
                        className="add-button"
                      >
                        Add a different ton AC
                      </button>

                      <button
                        disabled={acFields.length === 1}
                        onClick={() => handleRemoveAC(index)}
                        className="remove-ac-button"
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{ color: "#002b54" }}
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <div className="form-group2">
                <label htmlFor="hoursUsedPerDay">
                  How many hours are the ACs typically switched ON?
                </label>
                <div className="input-with-unit">
                  <input
                    type="number"
                    name="hoursUsedPerDay"
                    id="hoursUsedPerDay"
                    value={formData.hoursUsedPerDay}
                    onChange={handleChange}
                    required
                  />
                  <span>hrs</span>
                </div>
              </div>

              <div className="form-group3">
                <label htmlFor="hasSolar">
                  Do you have solar at your home?
                </label>
                <div className="select-wrapper">
                  <select
                    name="hasSolar"
                    id="hasSolar"
                    value={formData.hasSolar}
                    onChange={handleChange}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={submitting}
                  className="wefix-Button"
                >
                  {submitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="ac-images">
          <div className="ac-main-image">
            <img src={wefiximg} alt="img" />
          </div>

          <div className="ac-emissions">
            <div className="img2">
              <ReactSpeedometer
                width={240}
                height={240}
                needleTransitionDuration={4000}
                needleColor="black"
                value={Math.min(totalEmission || 0, 240)}
                minValue={0}
                maxValue={240}
                segments={3}
                segmentColors={["#4dbce8", "#ffa342", "#f00018"]}
                customSegmentStops={[0, 80, 160, 240]}
                forceRender={true}
                labelFontSize={"0px"}
                ringWidth={40}
                valueTextFontSize={"0px"}
              />

              <div
                className="wefix-emission-level"
                style={{
                  color:
                    totalEmission >= 160
                      ? "#f00018"
                      : totalEmission >= 80
                      ? "#ffa342"
                      : "#4dbce8",
                }}
              >
                {totalEmission >= 160
                  ? "Oops! You exceeded the carbon emissions limit."
                  : totalEmission >= 80
                  ? "You are within the carbon emissions limit."
                  : "Great! You are below the carbon emission limit."}
              </div>
            </div>
            <div className="wefix-emission-details">
              <div className="emission-meter11">
                {" "}
                <h2> 🍃 TOTAL EMISSION</h2>
              </div>
              <div
                className="emission-meter12"
                style={{
                  color:
                    totalEmission >= 160
                      ? "#f00018"
                      : totalEmission >= 80
                      ? "#ffa342"
                      : "#4dbce8",
                }}
              >
                <h2>
                  {totalEmission >= 0
                    ? `${totalEmission.toFixed(2)} Kg CO₂`
                    : "Calculating..."}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <WefixPopup totalEmission={totalEmission} onClose={handleClosePopup} />
      )}
      </HelmetProvider>
    </>
  );
};

export default Wefix;
